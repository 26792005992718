import CustomerIco from "@material-ui/icons/ViewList";
import RegExRules from '../views/RegExRules';
import Home from '../views/HomePage';
import HomeIco from "@material-ui/icons/Home";
import Customers from '../views/Customers';
import RuleIco from "@material-ui/icons/Build";

import Logs from '../views/Logs';
import LogsIco from "@material-ui/icons/BugReport";

import Dashboard from '../views/Dashboard';
import DashboardIco from "@material-ui/icons/Dashboard";
import Unauthorized from '../views/Unauthorized';

const indexRoutes = [
  {
    path: "/home",
    sidebarName: "Home page",
    navbarName: "Home",
    icon: HomeIco,
    component: Home,
    hasAuth: false
  },
  {
    path: "/rules",
    sidebarName: "RegEx rules",
    navbarName: "RegEx rules",
    icon: RuleIco,
    component: RegExRules,
    hasAuth: true
  },
  {
    path: "/customers",
    sidebarName: "Customers",
    navbarName: "Customers",
    icon: CustomerIco,
    component: Customers,
    hasAuth: true
  },
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: DashboardIco,
    component: Dashboard,
    hasAuth: false
  },
  {
    path: "/logs",
    sidebarName: "Logs",
    navbarName: "Logs",
    icon: LogsIco,
    component: Logs,
    hasAuth: false
  },
  {
    path: "/unauthorized",
    component: Unauthorized,
    navbarName: "Unauthorized",
    hasAuth: false
  },
  { redirect: true, path: "/", to: "/home", navbarName: "Redirect" }
];

export default indexRoutes;