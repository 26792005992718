import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog
} from "@material-ui/core";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "./Loading";

const LoginPopup = ({ ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const goHome = () => {
    props.history.push({
      pathname: "/home"
    });
  };

  return (
    <AzureAD provider={authProvider} forceLogin={false}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        // error = {};
        // error.errorMessage = "AADSTS50105: The signed in user '{EmailHidden}' is not assigned to a role for the application 'e2e0c567-e1c1-47d8-a788-e28377c452d0'(LTDMicroService). Trace ID: 953a387b-1608-403e-b3c7-a61edaef4000 Correlation ID: a4222276-c527-47b8-8faa-568e7fdd3f77 Timestamp: 2020-08-23 14:36:39Z";
        if (authenticationState === AuthenticationState.Unauthenticated) {
          return (
            <Dialog
              fullScreen={fullScreen}
              open={true}
              onClose={() => goHome()}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"You are not authorized. Please login."}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>Only Travelport corporate user can login here for now.</DialogContentText>
                {error && error.errorMessage && <p>{error.errorMessage}</p>}
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={login} color="secondary">
                  SSO login
                </Button>
                <Button onClick={() => goHome()}>Cancel</Button>
              </DialogActions>
            </Dialog>
          );
        } else if (authenticationState === AuthenticationState.InProgress) {
          return (
            <div style={{ marginTop: 30 }}>
              <Loading />
            </div>
          );
        } else if (error) {
          return (
            <p>
              <span>An error occured during authentication, please try again!</span>
              <Button variant="contained" color="secondary" onClick={login}>
                SSO login
              </Button>
            </p>
          );
        } else {
          return props.children;
        }
      }}
    </AzureAD>
  );
};

export default LoginPopup;
