import React from "react";
import CustomersTable from "../components/customers/CustomersTable";
import LoginPopup from "../components/controls/LoginPopup";

const Customers = props => {
  return (
    <LoginPopup {...props}>
      <CustomersTable />
    </LoginPopup>
  );
};

export default Customers;
