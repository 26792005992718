import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import ltdFetch from "../../utils/ltdFetch";
import tableColumns, { tableSetings } from "./rulesTableConfig";
import ReactResizeDetector from "react-resize-detector";

class RulesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: tableColumns,
      data: [],
      loading: true
    };
  }
  abortController = new AbortController();

  //baseUrl = "http://localhost:7071/api/";
  baseUrl = "https://travelportsolutionsmicroservices.azurewebsites.net/api/";

  componentDidMount() {
    ltdFetch(`${this.baseUrl}ticketdeadlinerule`, {
      mode: "cors",
      signal: this.abortController.signal
    })
      .then(response => response.json())
      .then(rules => {
        this.setState({
          data: rules,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  currentSize = "lg";

  onResize = (width, height) => {
    //const { size: currentSize } = this.state;
    let size = "xl";
    if (width < 1920 && width >= 1280) size = "lg";
    if (width < 1280 && width >= 960) size = "md";
    if (width < 960 && width >= 600) size = "sm";
    if (width < 600) size = "xs";
    if (this.currentSize !== size) {
      this.currentSize = size;
      if (size === "xs" || size === "sm") {
        const { columns } = this.state;
        columns.find(a => a.field === "timestamp").hidden = true;
        columns.find(a => a.field === "partitionKey").hidden = true;
        columns.find(a => a.field === "ruleExpressionExample").hidden = true;
        this.setState({ columns });
      } else {
        const { columns } = this.state;
        columns.find(a => a.field === "timestamp").hidden = false;
        columns.find(a => a.field === "partitionKey").hidden = false;
        columns.find(a => a.field === "ruleExpressionExample").hidden = false;
        this.setState({ columns });
      }
    }
  }

  addOrUpdateRule = (newData, oldData, resolve, reject) => {
    ltdFetch(`${this.baseUrl}ticketdeadlinerule`, {
      mode: "cors",
      method: "post",
      body: JSON.stringify(newData),
      signal: this.abortController.signal
    })
      .then(response => response.json())
      .then(rule => {
        const data = this.state.data;
        newData.timestamp = new Date().toISOString(); // rule.timestamp; - azure func returns old timestamp
        if (oldData === null) {
          data.push(newData);
        } else {
          const index = data.findIndex(a => a.rowKey === oldData.rowKey);
          data[index] = newData;
        }
        this.setState({ data }, () => resolve());
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  };

  removeRule = (oldData, resolve, reject) => {
    ltdFetch(`${this.baseUrl}ticketdeadlinerule`, {
      mode: "cors",
      method: "delete",
      body: JSON.stringify(oldData),
      signal: this.abortController.signal
    })
      .then(response => {
        if (response.status === 200 && response.ok) {
          let data = this.state.data;
          const index = data.findIndex(a => a.rowKey === oldData.rowKey);
          data.splice(index, 1);
          this.setState({ data }, () => resolve());
        } else {
          reject();
        }
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  };

  render() {
    return (
      <>
        <ReactResizeDetector
          handleWidth
          onResize={this.onResize}
          refreshMode="debounce"
          refreshRate={500}
        />
        <MaterialTable
          isLoading={this.state.loading}
          style={{ marginTop: 10 }}
          components={{
            Container: props => <Paper {...props} elevation={1} />
          }}
          title="LTD regex rules"
          columns={this.state.columns}
          data={this.state.data}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                this.addOrUpdateRule(newData, null, resolve, reject);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                this.addOrUpdateRule(newData, oldData, resolve, reject);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => this.removeRule(oldData, resolve, reject))
          }}
          options={tableSetings.options}
          localization={tableSetings.localization}
        />
      </>
    );
  }
}

export default RulesTable;
