import React from "react";
import { TextField } from "@material-ui/core";

const defaultCellPadding = 10;

const cellStyle = {
  padding: defaultCellPadding
};

const rulesTableColumns = [
  {
    title: "Airline",
    field: "airline",
    editComponent: props => (
      <TextField
        placeholder="Airline"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        inputProps={{
          "aria-label": "Airline",
          style: { maxWidth: "45px" },
          maxLength: 3
        }}
      />
    ),
    cellStyle
  },
  {
    title: "GDS",
    field: "partitionKey",
    lookup: { "1G": "1G", "1V": "1V", "1P": "1P" },
    editable: "onAdd",
    initialEditValue: "1G",
    cellStyle
  },
  {
    title: "Expression",
    field: "ruleExpression",
    cellStyle,
    editComponent: props => (
      <TextField
        placeholder="REGEX expression"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  },
  {
    title: "Expression example",
    field: "ruleExpressionExample",
    cellStyle
  },
  {
    title: "Last update",
    field: "timestamp",
    editable: "never",
    defaultSort: "desc",
    render: rowData =>
      rowData && (
        <>{new Date(rowData.timestamp).toLocaleString(navigator.language, { hour12: false })}</>
      ),
    cellStyle
  }
];

export default rulesTableColumns;

export const tableSetings = {
  options: {
    exportButton: true,
    sorting: true,
    loadingType: "overlay",
    exportDelimiter: ";",
    //padding: "dense",
    headerStyle: {
      padding: defaultCellPadding
    }
  },
  localization: {
    body: {
      emptyDataSourceMessage: "",
      filterRow: {
        filterTooltip: "Filter"
      }
    }
  }
};
