import React from "react";
import MaterialTable from "material-table";
import { Paper, IconButton } from "@material-ui/core";
import ltdFetch from "../../utils/ltdFetch";
import tableColumns, { tableSetings } from "./customersTableConfig";
import ReactResizeDetector from "react-resize-detector";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FileCopy from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

class CustomersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: tableColumns(this.showTokenInPopup),
      data: [],
      loading: true,
      viewTokenDialogOpen: false,
      token: "",
      customerName: "",
      copied: false,
      tokenPopupFullScreen: false
    };
  }

  abortController = new AbortController();

  //baseUrl = "http://localhost:7071/api/";
  baseUrl = "https://travelportsolutionsmicroservices.azurewebsites.net/api/";

  componentDidMount() {
    ltdFetch(`${this.baseUrl}customer`, {
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      signal: this.abortController.signal
    })
      .then(response => response.json())
      .then(rules => {
        this.setState({
          data: rules,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  addOrUpdateRule = (newData, oldData, resolve, reject) => {
    ltdFetch(`${this.baseUrl}customer`, {
      mode: "cors",
      method: "post",
      body: JSON.stringify(newData),
      signal: this.abortController.signal
    })
      .then(response => response.json())
      .then(rule => {
        const data = this.state.data;
        newData.timestamp = new Date().toISOString(); // rule.timestamp; - azure func returns old timestamp
        if (oldData === null) {
          data.push(newData);
        } else {
          const index = data.findIndex(a => a.rowKey === oldData.rowKey);
          data[index] = newData;
        }
        this.setState({ data }, () => resolve());
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  };

  removeRule = (oldData, resolve, reject) => {
    ltdFetch(`${this.baseUrl}customer`, {
      mode: "cors",
      method: "delete",
      body: JSON.stringify(oldData),
      signal: this.abortController.signal
    })
      .then(response => {
        if (response.status === 200 && response.ok) {
          let data = this.state.data;
          const index = data.findIndex(a => a.rowKey === oldData.rowKey);
          data.splice(index, 1);
          this.setState({ data }, () => resolve());
        } else {
          reject();
        }
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  };

  handleCloseTokenDialog = () => {
    this.setState({ viewTokenDialogOpen: false, token: "", customerName: "", copied: false });
  };

  showTokenInPopup = rowData => {
    this.setState({
      viewTokenDialogOpen: true,
      token: rowData.rowKey,
      customerName: rowData.customerName
    });
  };

  onResize = (width) => {
    if (width < 960) {
      const { columns } = this.state;
      columns.find(a => a.field === "timestamp").hidden = true;
      columns.find(a => a.field === "partitionKey").hidden = true;
      columns.find(a => a.field === "remarks").hidden = true;
      this.setState({ columns, tokenPopupFullScreen: true });
    } else {
      const { columns } = this.state;
      columns.find(a => a.field === "timestamp").hidden = false;
      columns.find(a => a.field === "partitionKey").hidden = false;
      columns.find(a => a.field === "remarks").hidden = false;
      this.setState({ columns, tokenPopupFullScreen: false });
    }
  };

  render() {
    return (
      <>
        <ReactResizeDetector
          handleWidth
          onResize={this.onResize}
          refreshMode="debounce"
          refreshRate={500}
        />
        <MaterialTable
          isLoading={this.state.loading}
          style={{ marginTop: 10 }}
          components={{
            Container: props => <Paper {...props} elevation={1} />
          }}
          title="Customers"
          columns={this.state.columns}
          data={this.state.data}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                this.addOrUpdateRule(newData, null, resolve, reject);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                this.addOrUpdateRule(newData, oldData, resolve, reject);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => this.removeRule(oldData, resolve, reject))
          }}
          options={tableSetings.options}
          localization={tableSetings.localization}
        />
        <Dialog
          open={this.state.viewTokenDialogOpen}
          onClose={this.handleCloseTokenDialog}
          aria-labelledby="form-dialog-title"
          fullScreen={this.state.tokenPopupFullScreen}
        >
          <DialogTitle id="form-dialog-title">Security Token/Key</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here is authorization token for customer:{" "}
                <span style={{ fontWeight: 600 }}>{this.state.customerName}</span>
            </DialogContentText>
            <div style={{ display: "flex" }}>
              <TextField
                autoFocus
                margin="dense"
                id="authToken"
                fullWidth
                value={this.state.token}
              />
              <CopyToClipboard
                text={this.state.token}
                onCopy={() => this.setState({ copied: true })}
              >
                <IconButton color="primary" style={{ marginLeft: 5, marginRight: 5 }}>
                  <FileCopy />
                </IconButton>
              </CopyToClipboard>
              {this.state.copied ? (
                <span style={{ color: "red", paddingTop: 12 }}>Copied.</span>
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTokenDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CustomersTable;
