import React from "react";
import { withRouter } from "react-router-dom";
import RulesTable from "../components/rules/RulesTable";
import LoginPopup from "../components/controls/LoginPopup";

let RegExRules = ({ ...props }) => {
  return (
    <LoginPopup {...props}>
      <RulesTable />
    </LoginPopup>
  );
};

export default withRouter(RegExRules);
