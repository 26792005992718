import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import indexRoutes from "../../routes/Index";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import menuAppbarStyles from "../../styles/MenuAppBar";
import logo from "../../assets/img/TravelportNoweLogo_40.png";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import ReactResizeDetector from "react-resize-detector";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function MenuAppBar({ ...props }) {
  const classes = menuAppbarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [openDrawer, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPageName = () => {
    return indexRoutes.find(a => a.path === props.location.pathname).navbarName;
  };

  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const getNameOnly = userName => {
    let name = userName.substring(userName.indexOf(",") + 2);
    return name;
  };

  const onResize = (width, height) => {
    // console.log(`Width: ${width} and height: ${height}`)
    currentWidth = width;
    setOpen(width > 1300);
  };

  let currentWidth = window.innerWidth;

  return (
    <>
      <ReactResizeDetector
        handleWidth
        onResize={onResize}
        refreshMode="debounce"
        refreshRate={500}
      />
      <CssBaseline />
      <AppBar
        position="sticky"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: openDrawer
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {useMediaQuery(theme.breakpoints.up("sm")) && "Last ticketing date - "}
            {getPageName()}
          </Typography>
          <AzureAD provider={authProvider} forceLogin={false}>
            {({ login, logout, authenticationState, error, accountInfo }) => {
              return (
                <div>
                  {authenticationState === AuthenticationState.Authenticated && (
                    <span className={classes.hideWelcome}>
                      Welcome {getNameOnly(accountInfo.account.name)}!
                    </span>
                  )}
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    {authenticationState === AuthenticationState.Unauthenticated
                      ? [
                          <MenuItem
                            key="0"
                            onClick={() => {
                              localStorage.setItem("loginClicked", true);
                              handleClose();
                              login();
                            }}
                          >
                            Login
                          </MenuItem>
                        ]
                      : [
                          <MenuItem
                            key="1"
                            onClick={() => {
                              handleClose();
                              logout();
                            }}
                          >
                            Logout
                          </MenuItem>,
                          <a
                            key="2"
                            target="blank"
                            href="https://portal.office.com/account/#personalinfo"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                          </a>
                        ]}
                  </Menu>
                </div>
              );
            }}
          </AzureAD>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })
        }}
        open={openDrawer}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="logo" className={classes.logoImg} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {indexRoutes.filter((a) => a.icon).map((prop, key) => {
            if (prop.redirect) return null;
            return (
              <NavLink
                onClick={e => {
                  e.preventDefault();
                  if(currentWidth < 1300){
                    handleDrawerClose();
                  }
                  props.history.push(prop.path);
                }}
                to={prop.path}
                activeClassName="active"
                key={key}
                className={classes.item}
              >
                <ListItem
                  button
                  className={clsx(classes.itemLink, {
                    [classes.activeItemLink]: activeRoute(prop.path)
                  })}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                  </ListItemIcon>
                  <ListItemText primary={prop.sidebarName} className={classes.itemText} />
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      </Drawer>
      <div
        className={clsx({
          [classes.contentMarginDrawerOpen]: openDrawer,
          [classes.contentMargin]: !openDrawer
        })}
      >
        {props.children}
      </div>
    </>
  );
}
