import React, { useEffect, useState } from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../authProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    height: "100%",
    outline: 0,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  card: {
    flexGrow: 1,
    maxWidth: 600,
    margin: 20,
    padding: 20,
    position: "relative",
    maxHeight: "calc(100% - 64px)",
    overflowY: "auto"
  },
  loginButton: {
    float: "right"
  }
}));

export default function LoginPage({ ...props }) {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (isAuth) props.history.push("/home");
  });
  const classes = useStyles();
  return (
    <AzureAD provider={authProvider} forceLogin={false}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        if (authenticationState === AuthenticationState.Unauthenticated) {
          return (
            <div className={classes.root}>
              <Card className={classes.card}>
                <p>You can login to Travelport AD SS</p>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.loginButton}
                  onClick={login}
                >
                  Login
                </Button>
              </Card>
            </div>
          );
        } else {
          setIsAuth(true);
          return null;
        }
      }}
    </AzureAD>
  );
}
