import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import indexRoutes from "./Index";
import MenuAppBar from "../components/controls/MenuAppBar";
import { authProvider } from "../authProvider";

export const MasterRoute = (props) => {
  useEffect(() => {
    var isUnauthorizedUser = localStorage.getItem("isUnauthorizedUser") === "true";
    if (isUnauthorizedUser) {
      localStorage.setItem("isUnauthorizedUser", false);
      var authPaths = ["/unauthorized"];
      if (!authPaths.some((a) => a.includes(props.location.pathname.toLowerCase()))) {
        props.history.push({ pathname: "/unauthorized" });
      }
    }
  });

  function authCallback(error, response) {
    //console.log(error);
    //console.log(response);
    var loginClicked = localStorage.getItem("loginClicked") === "true";
    if(loginClicked)
      localStorage.setItem("loginClicked", false);
    if (
      error &&
      error.errorMessage
      //&& error.errorMessage.includes("is not assigned to a role for the application")
    ) {
      var authPaths = indexRoutes.filter((a) => a.hasAuth === true).map((a) => a.path.toLowerCase());
      if (loginClicked || authPaths.some((a) => a.includes(props.location.pathname.toLowerCase()))) {
        localStorage.setItem("isUnauthorizedUser", true);
      }
    }
  }

  authProvider.handleRedirectCallback(authCallback);

  return (
    <>
      <MenuAppBar {...props}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
          })}
        </Switch>
      </MenuAppBar>
    </>
  );
};
