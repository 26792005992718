import React from "react";
import { TextField, Switch, IconButton } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

const defaultCellPadding = 10;

const cellStyle = {
  padding: defaultCellPadding
};

const tableColumns = showTokenInPopup => [
  {
    title: "Name",
    field: "customerName",
    cellStyle,
    editComponent: props => (
      <TextField
        placeholder="Customer name"
        fullWidth
        value={props.value === null ? "" : props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  },
  {
    title: "Token",
    field: "rowKey",
    cellStyle,
    editable: "never",
    sorting: false,
    render: rowData =>
      rowData && (
        <IconButton onClick={() => showTokenInPopup(rowData)}>
          <ViewIcon />
        </IconButton>
      )
  },
  {
    title: "GDS",
    field: "partitionKey",
    initialEditValue: "1G",
    lookup: { "1G": "1G", "1V": "1V", "1P": "1P" },
    editable: "onAdd",
    cellStyle,
    // editComponent: props => (
    //   <Select
    //       value={props.value}
    //       onChange={e => props.onChange(e.target.value)}
    //     >
    //       <MenuItem value={undefined}>SELECT</MenuItem>
    //       <MenuItem value={"1G"}>1G</MenuItem>
    //       <MenuItem value={"1P"}>1P</MenuItem>
    //       <MenuItem value={"1V"}>1V</MenuItem>
    //     </Select>
    // )
  },
  {
    title: "PCC(s)",
    field: "pcc",
    cellStyle,
    editComponent: props => (
      <TextField
        placeholder="PCC(s)"
        fullWidth
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  },
  {
    title: "Remarks",
    field: "remarks",
    cellStyle,
    editComponent: props => (
      <TextField
        placeholder="Free note"
        fullWidth
        value={props.value === null ? "" : props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  },
  {
    title: "Active",
    field: "enable",
    cellStyle,
    editComponent: props => (
      <Switch
        checked={props.value}
        onChange={e => props.onChange(e.target.checked)}
        value="checkedB"
        color="primary"
      />
    ),
    render: rowData => rowData && <>{rowData.enable ? "ON" : "OFF"}</>
  },
  {
    title: "Last update",
    field: "timestamp",
    editable: "never",
    defaultSort: "desc",
    render: rowData =>
      rowData && (
        <>{new Date(rowData.timestamp).toLocaleString(navigator.language, { hour12: false })}</>
      ),
    cellStyle
  }
];

export default tableColumns;

export const tableSetings = {
  options: {
    exportButton: true,
    sorting: true,
    loadingType: "overlay",
    exportDelimiter: ";",
    //padding: "dense",
    headerStyle: {
      padding: defaultCellPadding
    }
  },
  localization: {
    body: {
      emptyDataSourceMessage: "",
      filterRow: {
        filterTooltip: "Filter"
      }
    }
  }
};
