import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const menuAppbarStyles = makeStyles(theme => ({
  
  contentMargin: {
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 80,
      marginRight: 24
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentMarginDrawerOpen: {
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth + 24,
      marginRight: 24
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  rootFlex: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1
    }
  },
  hideWelcome:{
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  logoImg: {
    paddingRight: "50px",
    width: "220px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#FFFFFF"
    }
  },
  activeItemLink: {
    backgroundColor: "rgba(247, 228, 203, 1)"
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px"
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(10, 18, 26, 1)"
  },
  itemText: {
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: "rgba(10, 18, 26, 1)"
  }
}));

export default menuAppbarStyles;